import * as React from "react";
import { Box, Text, Heading, Center, Button } from "@chakra-ui/react";
import { Fonts } from "../utils/fonts";
import Layout from "../components/layout";
import Head from "../components/head";


const ContactPage = () => {
  return (
    <Layout>
              <Head title="Create 15 - Contact" description="Laser cutting and engraving in Johannesburg. Various materials like MDF, wood, bamboo and perspex."/>

      <Center mh="60px" color="black">
        <Heading as="h1">Contact</Heading>
      </Center>
      <Box textAlign="center" mb="600px">
        <Text fontSize="l">
          Please contact us via email at info@create15.com or submit a design using the button above.
        </Text>
      </Box>
    </Layout>
  );
};

export default ContactPage;
